.container {
  text-align: center;
}

.tableContainer {
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.header {
  background-color: white !important;
  margin-bottom: 0;
  padding: 0 !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.divider {
  margin-top: 0 !important;
  padding-top: 0 !important;
  border-top: 1.5px solid rgba(0, 0, 0, 0.6) !important;
}

.inputContainer {
  width: 100%;
  justify-content: right;
  display: flex;
  align-items: center;
}

.input {
  width: 50% !important;
  button {
    background-color: #1890ff !important;
    * {
      color: white;
    }
  }
}
.title {
  font-weight: 600;
  margin-right: 5px;
}

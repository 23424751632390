
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  padding: 10px;
  max-height: 60px;
}

.icon60{
  font-size: 60px;
}

.icon40{
  font-size: 40px;
}
  
  .card {
    background: white;
    min-width: 12em;
    min-height: 140px;
    max-height: 140px;
    border-radius: 0.6em;
    margin: 1em;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
  }
  
  .card:hover {
    transform: scale(1.03);
    max-height: 180px;
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);

    .cardBody h2{
      overflow: visible; 
      word-wrap: break-word;
      height:auto; 
    }
  }
  
  .cardBody {
    padding: 1.2em;
    text-align: center;
    font-size: 10px;
  
    h2{
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all ease 200ms;
    }
    
  }

  .selected {
    background-color: lightgray;
    transform: scale(1.03);
    max-height: 180px;
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);

    .cardBody h2{
      overflow: visible; 
      word-wrap: break-word;
      height:auto;  
    }
  }


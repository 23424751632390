
.description {
  display: inline-flex;
  flex-flow: column wrap;
  margin: 0.625rem;
  justify-content: center;
  flex: 1;
}
.name {
  font-family: Arial;
  margin: 0;
  color: #202020;
  font-weight: 400;
  font-size: 14px;
}
.rol {
  font-family: Arial;
  margin: 0;
  color: #808080;
  font-size: 12px;
  margin-bottom: 0;
}

.navbar {
    background-color: white !important;
    margin-bottom: 0;
    padding-bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: 500px){
        padding-left: 20px !important;
    }
}
.divider{
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: 1.5px solid rgba(0, 0, 0, 0.6) !important;
}
.search{
    width: 20% !important;
    margin-left: auto;
    vertical-align: center;
    padding-right: 5px;
    border-radius: 5px;

    @media (max-width: 850px) {
    
        width: 80% !important;
        
    }
    
    @media (max-width: 1200px){
    
        width: 50% !important;
        
    }

}


.menu{
    background-color: white;
    height: inherit;
    padding-top: 2.5px !important;
    overflow: hidden  !important;

    li{
        padding: 0 5px !important;     
    
    }

    button{
        border-radius: 5px;
    }
}
.content{
    background-color: white;
}


.breadcrumb{
    display: flex;
    white-space: nowrap;
    width: auto;
    text-overflow: ellipsis !important;
    overflow: hidden !important;

    span{
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        font-size: 15px;
    }
}

.breadcrumbItem{
    text-overflow: ellipsis;
    overflow: hidden;
}

.menuButtons{
    height: 100%;
    align-items: center;
    margin: 0;
    background-color: white;
    text-align: center;
}

.icon{
    font-size: 20px;
}

.backButton{
    border-radius: 5px;
    align-items: center;
    text-align: center
}
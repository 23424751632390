$avatar-size: 32px;
.avatarContainer {
  display: inline-flex;
  justify-content: center;
  margin: 0;
}
.container {
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
  align-self: center;
  justify-content: center;
  align-content: center;
  max-width: 100%;
  img {
    width: 100%;
    transition: all 0.4s ease 0s;
  }
  * {
    color: inherit !important;
  }
}
.circle {
  border-radius: 50%;
}
.square {
  border-radius: 10px;
}
.icon {
  padding: 5px;
}
// icon sizes
.xSmall {
  width: 20px;
  height: 20px;
}
.small {
  width: 26px;
  height: 26px;
}
.medium {
  width: 30px;
  height: 30px;
  margin: 5px;
}
.large {
  width: 36px;
  height: 36px;
  margin: 5px;
}
.xLarge {
  width: 40px;
  height: 40px;
  margin: 5px;
}
.xxLarge {
  width: 56px;
  height: 56px;
  margin: 5px;
}
// image loader
.loader {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.container{
    height: 100vh;
    width: 100vw;
    background-size: cover;
}

.cardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.card {
    background: white;
    min-width: 12em;
    min-height: 568px;
    max-width: 80%;
    max-height: 80%;
    border-radius: 0.6em;
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    margin: 1em;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;

    @media(max-width: 500px){
        min-height: 50vh;
    }
  }

  .cardBody {
    padding: 1.2em;
    text-align: center;
    margin: auto;
  }

  .cardImage{
      max-width: 50%;
      max-height: 50%;
      margin: auto;
      margin-top: 20px;
      
      img{
          flex: 1;
          height: 100%;
          width: 100%;
      }
  }


  

.nav {
    background-color: #001529;
    display: flex;
    width: auto;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    ul {
      display: flex;
      padding-left: 0;
      margin: 0;
      li {
        list-style: none;
        margin: 0 0.3125rem;
      }
    }
  }

  .fixedBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .fixedTop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .stickyTop {
    display: none;
  }

  .logo{
    height: 100%;
  }
  
.container{
    margin: 15px;
}

.formContainer{
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    justify-content: left;
    
}

.form{
    margin-top: 20px !important;
    width: 80%;
}


.formButtons{
    justify-content: end;
}

.formEdit{
    margin-left: 10px;
}

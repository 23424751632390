.sider {
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  
  .menu {
    padding: 10px 0;

    & [class~="ant-menu-item"] {
      margin-bottom: 10px;
    }
  }

  
}

.logo{
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

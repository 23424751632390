
.item {
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 21px;
  transition: all 0.3s;
  font-size: 0.75rem;
  line-height: 1.5;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: "PT Sans", sans-serif;
  @media only screen and (max-width: 768px) {
    // display: none !important;
  }
  .link {
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 1.5;
    color: white;
    font-weight: 600;
    vertical-align: middle;
    & > * {
      font-size: 12px !important;
      color: white;
      vertical-align: text-bottom;
      margin-right: 0.3125rem;
    }
    img {
      width: 16px;
      height: 16px;
      vertical-align: sub;
    }
  }
  &:hover {
    background-color: rgba(158, 207, 250, 0.25);
    text-decoration: none;
  }
}

.active.item {
  background-color: rgba(158, 207, 250, 0.25) !important;
}

.end {
  margin-left: auto !important;
}
.start {
  margin-right: auto !important;
}
.visible {
  display: flex !important;
}

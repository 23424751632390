.container{
    margin: 15px;
}

.formContainer{
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 50px;
    justify-content: left;
    
}

.form{
    margin-top: 20px !important;
    width: 80%;
}

.createButton{
    justify-content: end;
}

.createEntity{
    margin: 5px;
}

.divider{
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: 1.5px solid rgba(0, 0, 0, 0.6) !important;
}

.header{
    background-color: white !important;
    margin-bottom: 0;
    padding: 0 !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.userBox {
  position: relative;

  .userBoxButton {
    appearance: none;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: none;
    color: white;
    font-family: "PT Sans", sans-serif;
    transition: all 0.4s ease 0s;
    padding: 0 0.3125rem;
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      background-color: rgba(#001529, 0.2);
    }

    & > img {
      width: 20px;
    }
  }

  .userBoxList {
    display: block;
    margin-top: 0.625rem;
    padding: 0.625rem;
    position: absolute;
    z-index: 9999;
    bottom: auto;
    left: auto;
    right: 0;
    width: 166px;
    background-color: #001529;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.3s;
  }

  .userName {
    margin-right: 5px;
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }
}

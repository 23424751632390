html, body, .root{
  min-height: 100vh;
}

.layout {
  min-height: 100vh;
  

  .container {
    background-color: white !important;
    
  }

  .header{
    display: flex;
    
  }
  
}
.logo {
  display: flex;
  width: 80px;
  margin-right: 12px;
  margin-top: 7px;

  .brand{
    font-size: 50px;
    color: white;
  }
}
.navbarContainer{
  padding: 0px 0px !important;
}


.navCollapse {
  width: 100%;
  height: 100%;
  align-items: center;
  > ul {
    flex: 1;
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}

.formCancel{
    margin-right: 10px;
}

.formButtons{
    *{
        display: flex;
        justify-content: right;
    }
    
}
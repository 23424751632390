
.navList {
  justify-content: flex-start;
  flex: 1;
  display: flex;
  padding: 0.625rem 0.625rem 0.625rem 0;
  margin: 0;
  @media only screen and (max-width: 480px) {
    padding-right:0;
  }
  li {
    list-style: none;
    margin: 0 0.625rem;
  }
}
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
.center {
  justify-content: center;
}
.between {
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}

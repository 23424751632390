
.userBoxItem {
  text-align: right;
  margin-bottom: 0.625rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  .userBoxLink {
    color: #9b9b9b;
    font-size: 0.75rem;
    text-decoration: none;
    display: inline-block;
    padding: 1px;
    transition: color 0.3s;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}
